import { useState } from 'react';

import { getMessage } from '@/services/chat';
import { MessageType } from '@/types';
import { useRequest } from '@umijs/max';

/** 管理聊天相关逻辑状态 */
const useMessageStore = () => {
  const [allMessage, setAllMessage] = useState<Array<MessageType>>([]); // 全局聊天信息

  /** 每次流式数据完成之后，请求一下这个接口 */
  const { run: getMessageData } = useRequest(
    (id?: string) => {
      return getMessage({
        conversation_id: id,
      });
    },
    {
      manual: true, // 手动触发
      onSuccess: (data) => {
        // 获取数据后更新聊天数据
        if (data) {
          setAllMessage(data);
        }
      },
    },
  );

  return {
    allMessage,
    setAllMessage,
    getMessageData,
  };
};

export default useMessageStore;
