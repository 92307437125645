import { request } from '@umijs/max';

/** 登录 */
export async function login(body: any) {
  return request<any>('/api/v1/auth/phone', {
    method: 'POST',
    data: body,
  });
}

/** 发送验证码 */
export async function sendCode(body: any) {
  return request<any>('/api/v1/auth/phone/code', {
    method: 'POST',
    data: body,
  });
}

/** 阿里云验证码 */
export async function captcha(body: any) {
  return request<any>('/api/v1/auth/captcha', {
    method: 'POST',
    data: body,
  });
}

/** 用户信息 */
export async function getUser(params?: any) {
  return request<any>('/api/v1/user', {
    method: 'GET',
    params: {
      ...params,
    },
  });
}

