import { useState } from 'react';

export default function useChat() {
  const [activeAudioId, setActiveAudioId] = useState<string | null>(null);

  // 设置当前播放的音频 ID
  const playAudio = (id: string | null) => {
    setActiveAudioId(id);
  };

  // 停止播放
  const stopAudio = () => {
    setActiveAudioId(null);
  };

  return {
    activeAudioId,
    playAudio,
    stopAudio,
  };
}
