export const serverUrl = {
  development: 'http://127.0.0.1:8000',
  testing: 'http://api.poppaaws.com/testing',
  preview: 'http://api.poppaaws.com/preview',
  release: 'http://api.poppaaws.com',
};

export const websocketSubscribeVhost = {
  development: 'poppaaws',
  testing: 'poppaaws_testing',
  preview: 'poppaaws_preview',
  release: 'poppaaws',
};

export const websocketSubscribeURL = {
  development: 'ws://127.0.0.1:15674/ws',
  testing: 'ws://rabbitmq.poppaaws.com:15674/ws',
  preview: 'ws://rabbitmq.poppaaws.com:15674/ws',
  release: 'ws://rabbitmq.poppaaws.com:15674/ws',
};



