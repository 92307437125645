import { useState } from 'react';

/** 放置常用的全局id */
const useIdStore = () => {
  const [conversationId, setConversationId] = useState<number>(0); // 对话id

  return {
    conversationId,
    setConversationId,
  };
};

export default useIdStore;
