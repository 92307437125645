import { request } from '@umijs/max';


/** 获取默认角色 */
export async function getCharacter(params?: any) {
  return request<any>('/api/v1/character/my/default', {
    method: 'GET',
    params: {
      ...params,
    },
  });
}
