import { useState } from 'react';

export default function useGlobal() {
  const [motion, setMotion] = useState<string | null>(null);

  return {
    motion,
    setMotion,
  };
}
